import { NG_EVENT_PLUGINS } from '@taiga-ui/event-plugins';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { TuiRoot, TuiDialog } from '@taiga-ui/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { getBrowserLang, provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { VermoApiModule } from '@vermo-core/api.module';
import { confirmKeycloakRealmExist } from '@vermo-core/interceptors/match-failed-keycloak.interceptor';
import { routes } from './app.routes';
import { TranslocoHttpLoader } from './transloco-loader';

export const TENANT_ID_LS_KEY = 'tenantId';
export const LANGUAGE_KEY = 'lang';

import { DynamicConfigService } from '@vermo-core/services/dynamic-config.service';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { firstValueFrom } from 'rxjs';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

function initializeKeycloak(
  keycloak: KeycloakService,
  dynamicConfigService: DynamicConfigService,
) {
  const tenantId = window.sessionStorage.getItem(TENANT_ID_LS_KEY);
  if (!tenantId || tenantId === 'undefined') {
    return () => false;
  }

  return () =>
    firstValueFrom(dynamicConfigService.getConfig()).then((config) =>
      keycloak.init({
        config: {
          url: config.keycloakUrl,
          realm: tenantId || 'platform-ui-dev',
          clientId: 'platform-ui',
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html',
        },
      }),
    );
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, DynamicConfigService],
    },

    provideTransloco({
      config: {
        availableLangs: ['en', 'pl'],
        defaultLang:
          window.localStorage.getItem(LANGUAGE_KEY) || getBrowserLang() || 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({
      defaultLocale: 'pl-PL',
    }),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([confirmKeycloakRealmExist]),
    ),
    provideAnimations(),
    importProvidersFrom(VermoApiModule, KeycloakAngularModule, TuiDialog),
    { provide: LOCALE_ID, useValue: 'en-UK' },
    // KeycloakService,
    provideRouter(routes),
    NG_EVENT_PLUGINS,
  ],
};
